














































import { useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import auth from '@/composables/auth'
import CustomLink from '@/components/g/CustomLink.vue'
import Terminal from '@/components/my/terminals/Terminal.vue'
import TerminalAddress from '@/components/my/terminals/TerminalAddress.vue'

export default defineComponent({
  name: 'ChargingTerminalsEdit',
  components: {
    Terminal,
    CustomLink,
    TerminalAddress,
  },
  setup(_props, ctx) {
    const { $route } = ctx.root
    const { uuid } = $route.params

    const { i18n } = useState('my', ['i18n'])

    const isLoad = ref(false)
    const isFindTerminal = ref(false)

    const terminal = ref([])

    onBeforeMount(async () => {
      await axios
        .get(`${getApiUrl()}/myresa/bornes/${uuid}`)
        .then(res => {
          terminal.value = res.data
          isLoad.value = true
          isFindTerminal.value = true
        })
        .catch(e => {
          logger.trace(e)
          logger.trace('[terminal:onBeforeMount] FAILED ')
        })
    })

    const onSubmit = async () => {
      await axios
        .delete(`${getApiUrl()}/myresa/bornesDelete/${uuid}`, {
          data: { source: '' },
        })
        .then(resp => {
          ctx.root.$router.push({
            name: 'chargingTerminalsStatus',
            params: { status: 'successd' },
          })
        })
        .catch(e => {
          logger.trace('[terminal:Delete] FAILED')
          ctx.root.$router.push({
            name: 'chargingTerminalsStatus',
            params: { status: 'error' },
          })
        })
    }

    return {
      i18n,
      isLogged: auth.isLogged,
      terminal,
      uuid,
      onSubmit,
      isLoad,
      isFindTerminal,
    }
  },
})
